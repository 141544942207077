import React from "react";
import Layout from "../../../components/Layout";

import SamsoniteStory from "../../../components/SamsoniteStory";

const SamsoniteStoryPage = () => (
  <Layout>
    <SamsoniteStory />
  </Layout>
);

export default SamsoniteStoryPage;
