import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { Primary } from "../../components/Section";
import { HrefButtonInverse } from "../../components/Button";
const SamsoniteStory = (props) => {
  return (
    <section>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <h2>The Samsonite Story</h2>
            <p>
              Hello! I am Dr. Michael J, Founder of MJMD Global Group and also
              the creator and inventor of Touchless Flatware. Do you remember
              the samsonite luggage commercial with gorillas throwing the
              suitcase around the cage? Well, I used a samsonite suitcase while
              traveling with my Christian Service Brigade team in 7th grade on a
              trip. While at the airport, I felt as though I could make that
              piece of luggage better. At that time there were no wheels on the
              suitcase and the handle was limited. I then said to myself, “I can
              make and create a better suitcase.” Well I never did.
            </p>
            <p>
              One day 6 to 7 years later while I was walking in the airport
              returning to my home in Los Angeles for a spring break vacation
              from college at UC Berkeley, I suddenly realized how people were
              traveling with the new innovative suitcases that we commonly see
              when traveling today. I said to myself, “Wow! I envisioned how
              people could travel with a different kind of suitcase.” I saw this
              vision in 7th grade! However, I did not act upon my new idea at
              that time.
            </p>
            <p>
              Now, another 6-7 years passed. One day I went to a restaurant in
              Fort Lauderdale, Florida for lunch. I saw a booth table become
              available as I was walking in the door. The restaurant host said,
              “Feel free to sit at the table that opened up. I will have someone
              clean the table off shortly.”
            </p>
            <p>
              When I sat down at the table, I started to use my waiter skills to
              organize the table for the wait staff. I use to wait tables while
              I was a student at Howard University and during my first year of
              medical school at Georgetown University. A waitress dropped off a
              “roll-up” (flatware in a napkin) and said that she would be right
              back to remove the dishes remaining on the table. I then unrolled
              the napkin and used it to help wipe the table. However, the table
              did not seem clean enough to put the flatware that was in my hand
              on the table. As I was waiting for the waitress to return to wipe
              my the table down, I continued to hold the flatware in my hand.
              And I continued to wait .. and wait.
            </p>
            <p>
              Suddenly, I said to myself, “There has to be a better way.” There
              must be a way that I can sit the flatware on the table while
              avoiding the germs on the table.
            </p>
            <p>
              And yes!!! Because of my experience and vision with the Samsonite
              luggage, I was not going to let the idea of “Touchless Flatware”
              stop.
            </p>
            <p>
              From that day forward I have been developing and creating the
              spirit of MJ Global within the product development of Touchless
              Flatware. I began drawing and designing the new flatware as soon
              as I left the restaurant. I was in a patent attorney’s office the
              next Monday.
            </p>
            <p>
              I applied the knowledge and spirit resulting from being a Hand and
              Microsurgeon to the design of Touchless Flatware. Fortunately,
              multiple patents have been awarded.
            </p>
            <p>
              The one lesson that I learned from this Samsonite Story is to
              don’t let an idea die… develop it … embrace it. And that is what
              is meant when we say “Go Touchless!” and
              <strong>
                <i>“Create New History!”</i>
              </strong>
            </p>
            <br />
            <HrefButtonInverse
              href="//www.touchlessflatware.com"
              title="Learn More"
              target="_blank"
            >
              Visit Touchless Flatware
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
    </section>
  );
};

SamsoniteStory.propTypes = {};

export default SamsoniteStory;
